import React from "react";

import styles from "./Checkbox.module.scss";

export interface InputProps {
    label?: string;
    checked: boolean;
    onChange: (event) => void;
}

const Checkbox: React.FC<InputProps> = ({ label, checked, onChange }) => (
    <label className={styles.checkboxContainer}>
        {label}
        <input type="checkbox" checked={checked} onChange={onChange} />
        <span className={styles.checkboxCheckmark}></span>
    </label>
);

export default Checkbox;
