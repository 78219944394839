import React, { useCallback } from "react";
import "../InitiateOnboarding.scss";
import { Fund, FundFile, FundType, SingleFund, SingleFundFiles, SubFund, SubFundFiles, UmbrellaFiles, UmbrellaFund } from "../../types";
import { Card, CardContent, Chip, DragAndDrop, Label, Radio } from "@components/common";
import { format } from "date-fns";
import { API_ENDPOINT } from "@src/common/config";
import axios from "@src/common/http";
import { useAppUserContext } from "@src/common/Context";
import { ERole } from "@src/common/types";
import LabeledDatepicker from "@components/common/LabeledDatepicker";
import LabeledInput from "@components/common/LabeledInput";

const baseClass = "acl-page-initiate-onboarding";

type FundDetailsProps = {
    fund: Fund;
    isDisabled?: boolean;
    updateFund: (newFund: Fund) => void;
};

const dateFormat = "yyyy-MM-dd";

const downloadFile = async (file: FundFile): Promise<any> => {
    const { rlsWizardId, externalId } = file || {};
    if (!rlsWizardId || !externalId) {
        console.error(`Missing file info for download.`);
        return;
    }
    const { data } = await axios.get(`${API_ENDPOINT}/rls-wizard/${rlsWizardId}/download-file/${externalId}`);
    const { name, buffer } = data;
    const url = window.URL.createObjectURL(new Blob([new Uint8Array(buffer.data)], { type: buffer?.type }));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", name);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
};

const UmbrellaDetails: React.FC<FundDetailsProps> = ({ fund, updateFund, isDisabled = false }) => {
    const { name, details: fundDetails } = fund;
    const details = fundDetails as UmbrellaFund;
    const { domicile, fiscalYearEnd, regulatorName, fundOfferingToBoth, isRegulated, ucits, files } = details;
    const {
        articlesOfAssociationFiles,
        authorizedSignatoriesFiles,
        latestAnnualReportFiles,
        latestProspectusFiles,
        latestSemiAnnualReportFiles,
        ucitsAttestationFiles,
        marketingFiles,
        priipsKidsFiles,
        supplementFiles,
    } = files;

    const updateFiles = useCallback(
        (filesKey: keyof UmbrellaFiles, newFiles): void => {
            const newDetails: UmbrellaFund = { ...details, files: { ...files, [filesKey]: newFiles } };
            const newFund = { ...fund, details: newDetails };
            updateFund(newFund);
        },
        [fund, details, files, updateFund],
    );

    const updateFundDetails = useCallback(
        (detailsKey: keyof UmbrellaFund, value): void => {
            updateFund({ ...fund, details: { ...details, [detailsKey]: value } });
        },
        [fund, details, updateFund],
    );

    const disableUcits = useCallback(() => {
        const newDetails: UmbrellaFund = { ...details, ucits: false, files: { ...files, ucitsAttestationFiles: [] } };
        updateFund({ ...fund, details: { ...newDetails } });
    }, [fund, details, files, updateFund]);

    const enableUcits = useCallback(() => {
        const newDetails: UmbrellaFund = { ...details, ucits: true, isRegulated: true };
        updateFund({ ...fund, details: { ...newDetails } });
    }, [fund, details, updateFund]);

    const setIsRegulated = useCallback(
        (value: boolean) => {
            if (value) {
                updateFund({ ...fund, details: { ...details, isRegulated: true } });
            } else {
                updateFund({ ...fund, details: { ...details, isRegulated: false, fundOfferingToBoth: false } });
            }
        },
        [fund, details, updateFund],
    );

    return (
        <div>
            <Chip name={"Umbrella"} size="small" type="disabled" />
            <LabeledInput
                type="text"
                label="Fund Name"
                value={name}
                placeholder="Enter Fund Name"
                className={`${baseClass}__singleInput`}
                onChange={(event): void => updateFund({ ...fund, name: event })}
            />
            <LabeledInput
                type="text"
                label="Fund Domicile"
                value={domicile}
                placeholder="Enter Fund Domicile"
                className={`${baseClass}__singleInput`}
                onChange={(event): void => updateFundDetails("domicile", event)}
            />

            <div className={`${baseClass}__labeledRadioGroup`}>
                <Label>UCITS</Label>
                <div className={`${baseClass}__labeledRadioGroup-wrapper`}>
                    <Radio
                        name="ucits"
                        checked={ucits}
                        label="Yes"
                        onChange={(): void => {
                            enableUcits();
                        }}
                    />
                    <Radio
                        name="ucits"
                        checked={ucits !== undefined && !ucits}
                        label="No"
                        onChange={(): void => {
                            disableUcits();
                        }}
                    />
                </div>
            </div>

            <LabeledDatepicker
                wrapperClassName={`${baseClass}__datepickerWrapper`}
                label="Fiscal Year End"
                selected={fiscalYearEnd ? new Date(fiscalYearEnd) : null}
                placeholderText="Enter Fiscal Year End"
                onChange={(event): void => updateFundDetails("fiscalYearEnd", event && format(new Date(event), dateFormat))}
            />

            <div className={`${baseClass}__labeledRadioGroup`}>
                <Label>Is the fund regulated / supervised by governmental authority?</Label>
                <div className={`${baseClass}__labeledRadioGroup-wrapper`}>
                    <Radio name="isRegulated" checked={isRegulated} label="Yes" onChange={(): void => setIsRegulated(true)} />
                    <Radio name="isRegulated" checked={isRegulated !== undefined && !isRegulated} label="No" onChange={(): void => setIsRegulated(false)} />
                </div>
            </div>

            {isRegulated && (
                <LabeledInput
                    type="text"
                    label="Regulator / Supervisor Authority Name"
                    value={name}
                    placeholder="Enter Regulator / Supervisor Authority Name"
                    className={`${baseClass}__singleInput`}
                    onChange={(event): void => updateFundDetails("regulatorName", event)}
                />
            )}

            {isRegulated && (
                <div className={`${baseClass}__singleInput`}>
                    <Radio
                        name="fundOfferingToBoth"
                        checked={fundOfferingToBoth}
                        label="The fund will be offered to Non-Qualified and Qualified Investors in Switzerland (registration with FINMA necessary)"
                        onChange={(): void => updateFundDetails("fundOfferingToBoth", true)}
                    />
                </div>
            )}
            <div className={`${baseClass}__singleInput`}>
                <Radio
                    name="fundOfferingToBoth"
                    checked={fundOfferingToBoth !== undefined && !fundOfferingToBoth}
                    label="The fund will be offered only to Qualified Investors in Switzerland"
                    onChange={(): void => updateFundDetails("fundOfferingToBoth", false)}
                />
            </div>
            <div className={`${baseClass}__fileList`}>
                <DragAndDrop
                    disableUpload={isDisabled}
                    docType="PR"
                    label="Latest Prospectus / OM / PPM"
                    files={latestProspectusFiles ?? []}
                    setFiles={newFiles => updateFiles("latestProspectusFiles", newFiles)}
                    onFileClick={downloadFile}
                />
                <DragAndDrop
                    disableUpload={isDisabled}
                    docType="SUP"
                    label={
                        <>
                            Supplements*<i> (if applicable)</i>
                        </>
                    }
                    files={supplementFiles ?? []}
                    setFiles={newFiles => updateFiles("supplementFiles", newFiles)}
                    onFileClick={downloadFile}
                />
                <DragAndDrop
                    disableUpload={isDisabled}
                    docType="AR"
                    label={
                        <>
                            Latest Annual Report*<i> (if such document is available)</i>
                        </>
                    }
                    files={latestAnnualReportFiles ?? []}
                    setFiles={newFiles => updateFiles("latestAnnualReportFiles", newFiles)}
                    onFileClick={downloadFile}
                />
                <DragAndDrop
                    disableUpload={isDisabled}
                    docType="SAR"
                    label={
                        <>
                            Latest Semi-Annual Report*<i> (if such document is available)</i>
                        </>
                    }
                    files={latestSemiAnnualReportFiles ?? []}
                    setFiles={newFiles => updateFiles("latestSemiAnnualReportFiles", newFiles)}
                    onFileClick={downloadFile}
                />
                <DragAndDrop
                    disableUpload={isDisabled}
                    docType="AoA"
                    label="Article of Association/Incorporation or Fund Regulation"
                    files={articlesOfAssociationFiles ?? []}
                    setFiles={newFiles => updateFiles("articlesOfAssociationFiles", newFiles)}
                    onFileClick={downloadFile}
                />
                <DragAndDrop
                    disableUpload={isDisabled}
                    docType="ASL"
                    label="List of the Authorized Signatories for the Fund and the Fund Provider"
                    files={authorizedSignatoriesFiles ?? []}
                    setFiles={newFiles => updateFiles("authorizedSignatoriesFiles", newFiles)}
                    onFileClick={downloadFile}
                />
                <DragAndDrop
                    disableUpload={isDisabled}
                    docType="MKTG"
                    label="Marketing Documents"
                    files={marketingFiles ?? []}
                    setFiles={newFiles => updateFiles("marketingFiles", newFiles)}
                    onFileClick={downloadFile}
                />
                <DragAndDrop
                    disableUpload={isDisabled}
                    docType="PRP"
                    label={
                        <>
                            PRIIPs KIDs*<i> (if such document is available)</i>
                        </>
                    }
                    files={priipsKidsFiles ?? []}
                    setFiles={newFiles => updateFiles("priipsKidsFiles", newFiles)}
                    onFileClick={downloadFile}
                    extensions={[".pdf", ".docx", ".zip"]}
                />
                {ucits && (
                    <DragAndDrop
                        disableUpload={isDisabled}
                        docType="UCITS-att"
                        label="UCITS Attestation"
                        files={ucitsAttestationFiles ?? []}
                        setFiles={newFiles => updateFiles("ucitsAttestationFiles", newFiles)}
                        onFileClick={downloadFile}
                    />
                )}
            </div>
        </div>
    );
};

const SingleFundDetails: React.FC<FundDetailsProps> = ({ fund, updateFund, isDisabled = false }) => {
    const { name, details: fundDetails } = fund;
    const details: SingleFund = fundDetails as SingleFund;
    const { authorizationDate, fiscalYearEnd, domicile, files, fundOfferingToBoth, isRegulated, regulatorName, ucits } = details;
    const {
        articlesOfAssociationFiles,
        authorizedSignatoriesFiles,
        latestAnnualReportFiles,
        latestProspectusFiles,
        latestSemiAnnualReportFiles,
        marketingFiles,
        priipsKidsFiles,
        ucitsAttestationFiles,
    } = files || {};

    const updateFiles = useCallback(
        (filesKey: keyof SingleFundFiles, newFiles) => {
            const newDetails: SingleFund = { ...details, files: { ...files, [filesKey]: newFiles } };
            const newFund = { ...fund, details: newDetails };
            updateFund(newFund);
        },
        [fund, details, files, updateFund],
    );

    const updateFundDetails = useCallback(
        (detailsKey: keyof SingleFund, value): void => {
            updateFund({ ...fund, details: { ...details, [detailsKey]: value } });
        },
        [fund, details, updateFund],
    );

    const disableUcits = useCallback(() => {
        const newDetails: SingleFund = { ...details, ucits: false, files: { ...files, ucitsAttestationFiles: [] } };
        updateFund({ ...fund, details: { ...newDetails } });
    }, [fund, details, files, updateFund]);

    const enableUcits = useCallback(() => {
        const newDetails: SingleFund = { ...details, ucits: true, isRegulated: true };
        updateFund({ ...fund, details: { ...newDetails } });
    }, [fund, details, updateFund]);

    const setIsRegulated = useCallback(
        (value: boolean) => {
            if (value) {
                updateFund({ ...fund, details: { ...details, isRegulated: true } });
            } else {
                updateFund({ ...fund, details: { ...details, isRegulated: false, fundOfferingToBoth: false } });
            }
        },
        [fund, details, updateFund],
    );

    return (
        <div>
            <Chip name={"Single Fund"} size="small" type="disabled" />
            <LabeledInput
                type="text"
                label="Fund Name"
                value={name}
                placeholder="Enter Fund Name"
                className={`${baseClass}__singleInput`}
                onChange={(event): void => updateFund({ ...fund, name: event })}
            />
            <LabeledInput
                type="text"
                label="Fund Domicile"
                value={domicile}
                placeholder="Enter Fund Domicile"
                className={`${baseClass}__singleInput`}
                onChange={(event): void => updateFundDetails("domicile", event)}
            />

            <div className={`${baseClass}__labeledRadioGroup`}>
                <Label>UCITS</Label>
                <div className={`${baseClass}__labeledRadioGroup-wrapper`}>
                    <Radio
                        name="ucits"
                        checked={ucits}
                        label="Yes"
                        onChange={(): void => {
                            enableUcits();
                        }}
                    />
                    <Radio
                        name="ucits"
                        checked={ucits !== undefined && !ucits}
                        label="No"
                        onChange={(): void => {
                            disableUcits();
                        }}
                    />
                </div>
            </div>
            <LabeledDatepicker
                wrapperClassName={`${baseClass}__datepickerWrapper`}
                label="Fiscal Year End"
                selected={fiscalYearEnd ? new Date(fiscalYearEnd) : null}
                placeholderText="Enter Fiscal Year End"
                onChange={(event): void => updateFundDetails("fiscalYearEnd", event && format(new Date(event), dateFormat))}
            />

            <div className={`${baseClass}__labeledRadioGroup`}>
                <Label>Is the fund regulated / supervised by governmental authority?</Label>
                <div className={`${baseClass}__labeledRadioGroup-wrapper`}>
                    <Radio name="isRegulated" checked={isRegulated} label="Yes" onChange={(): void => setIsRegulated(true)} />
                    <Radio name="isRegulated" checked={isRegulated !== undefined && !isRegulated} label="No" onChange={(): void => setIsRegulated(false)} />
                </div>
            </div>

            {isRegulated && (
                <>
                    <LabeledInput
                        type="text"
                        label="Regulator / Supervisor Authority Name"
                        value={regulatorName}
                        placeholder="Enter Regulator / Supervisor Authority Name"
                        className={`${baseClass}__singleInput`}
                        onChange={(event): void => updateFundDetails("regulatorName", event)}
                    />
                    <LabeledDatepicker
                        wrapperClassName={`${baseClass}__datepickerWrapper`}
                        label="Home Country Authorization Date"
                        selected={authorizationDate ? new Date(authorizationDate) : null}
                        placeholderText="Enter Fiscal Year End"
                        onChange={(event): void => updateFundDetails("authorizationDate", event && format(new Date(event), dateFormat))}
                    />
                </>
            )}

            {isRegulated && (
                <div className={`${baseClass}__singleInput`}>
                    <Radio
                        name="fundOfferingToBoth"
                        checked={fundOfferingToBoth}
                        label="The fund will be offered to Non-Qualified and Qualified Investors in Switzerland (registration with FINMA necessary)"
                        onChange={(): void => updateFundDetails("fundOfferingToBoth", true)}
                    />
                </div>
            )}
            <div className={`${baseClass}__singleInput`}>
                <Radio
                    name="fundOfferingToBoth"
                    checked={fundOfferingToBoth !== undefined && !fundOfferingToBoth}
                    label="The fund will be offered only to Qualified Investors in Switzerland"
                    onChange={(): void => updateFundDetails("fundOfferingToBoth", false)}
                />
            </div>
            <DragAndDrop
                disableUpload={isDisabled}
                docType="PR"
                label="Latest Prospectus / OM / PPM"
                files={latestProspectusFiles ?? []}
                setFiles={newFiles => updateFiles("latestProspectusFiles", newFiles)}
                onFileClick={downloadFile}
            />
            <DragAndDrop
                disableUpload={isDisabled}
                docType="AR"
                label={
                    <>
                        Latest Annual Report*<i> (if such document is available)</i>
                    </>
                }
                files={latestAnnualReportFiles ?? []}
                setFiles={newFiles => updateFiles("latestAnnualReportFiles", newFiles)}
                onFileClick={downloadFile}
            />
            <DragAndDrop
                disableUpload={isDisabled}
                docType="SAR"
                label={
                    <>
                        Latest Semi-Annual Report*<i> (if such document is available)</i>
                    </>
                }
                files={latestSemiAnnualReportFiles ?? []}
                setFiles={newFiles => updateFiles("latestSemiAnnualReportFiles", newFiles)}
                onFileClick={downloadFile}
            />
            <DragAndDrop
                disableUpload={isDisabled}
                docType="AoA"
                label="Article of Association/Incorporation or Fund Regulation"
                files={articlesOfAssociationFiles ?? []}
                setFiles={newFiles => updateFiles("articlesOfAssociationFiles", newFiles)}
                onFileClick={downloadFile}
            />
            <DragAndDrop
                disableUpload={isDisabled}
                docType="ASL"
                label="List of the Authorized Signatories for the Fund and the Fund Provider"
                files={authorizedSignatoriesFiles ?? []}
                setFiles={newFiles => updateFiles("authorizedSignatoriesFiles", newFiles)}
                onFileClick={downloadFile}
            />
            <DragAndDrop
                disableUpload={isDisabled}
                docType="MKTG"
                label="Marketing Documents"
                files={marketingFiles ?? []}
                setFiles={newFiles => updateFiles("marketingFiles", newFiles)}
                onFileClick={downloadFile}
            />
            <DragAndDrop
                disableUpload={isDisabled}
                docType="PRP"
                label={
                    <>
                        PRIIPs KIDs*<i> (if such document is available)</i>
                    </>
                }
                files={priipsKidsFiles ?? []}
                setFiles={newFiles => updateFiles("priipsKidsFiles", newFiles)}
                onFileClick={downloadFile}
            />
            {ucits && (
                <DragAndDrop
                    disableUpload={isDisabled}
                    docType="UCITS-att"
                    label="UCITS Attestation"
                    files={ucitsAttestationFiles ?? []}
                    setFiles={newFiles => updateFiles("ucitsAttestationFiles", newFiles)}
                    onFileClick={downloadFile}
                />
            )}
        </div>
    );
};

const SubFundDetails: React.FC<FundDetailsProps> = ({ fund, updateFund, isDisabled = false }) => {
    const { name, details: fundDetails } = fund;
    const details: SubFund = fundDetails as SubFund;
    const { authorizationDate, files } = details;
    const { marketingFiles, priipsKidsFiles, prospectusFiles, supplementFiles } = files || {};

    const updateFiles = useCallback(
        (filesKey: keyof SubFundFiles, newFiles) => {
            const newDetails: SubFund = { ...details, files: { ...files, [filesKey]: newFiles } };
            const newFund = { ...fund, details: newDetails };
            updateFund(newFund);
        },
        [fund, details, files, updateFund],
    );

    const updateFundDetails = useCallback(
        (detailsKey: keyof SubFund, value): void => {
            updateFund({ ...fund, details: { ...details, [detailsKey]: value } });
        },
        [fund, details, updateFund],
    );

    return (
        <div>
            <Chip name={"Subfund"} size="small" type="disabled" />
            <LabeledInput
                type="text"
                label="Subfund Name"
                value={name}
                placeholder="Enter Subfund Name"
                className={`${baseClass}__singleInput`}
                onChange={(event): void => updateFund({ ...fund, name: event })}
            />
            <LabeledDatepicker
                wrapperClassName={`${baseClass}__datepickerWrapper`}
                label="Home Country Authorization Date"
                selected={authorizationDate ? new Date(authorizationDate) : null}
                placeholderText="Enter Fiscal Year End"
                onChange={(event): void => updateFundDetails("authorizationDate", event && format(new Date(event), dateFormat))}
            />
            <DragAndDrop
                disableUpload={isDisabled}
                docType="PR"
                label="Prospectus"
                files={prospectusFiles ?? []}
                setFiles={newFiles => updateFiles("prospectusFiles", newFiles)}
                onFileClick={downloadFile}
            />
            <DragAndDrop
                disableUpload={isDisabled}
                docType="SUP"
                label={
                    <>
                        Supplements*<i> (if applicable)</i>
                    </>
                }
                files={supplementFiles ?? []}
                setFiles={newFiles => updateFiles("supplementFiles", newFiles)}
                onFileClick={downloadFile}
            />
            <DragAndDrop
                disableUpload={isDisabled}
                docType="MKTG"
                label="Marketing Documents"
                files={marketingFiles ?? []}
                setFiles={newFiles => updateFiles("marketingFiles", newFiles)}
                onFileClick={downloadFile}
            />
            <DragAndDrop
                disableUpload={isDisabled}
                docType="PRP"
                label={
                    <>
                        PRIIPs KIDs*<i> (if such document is available)</i>
                    </>
                }
                files={priipsKidsFiles ?? []}
                setFiles={newFiles => updateFiles("priipsKidsFiles", newFiles)}
                onFileClick={downloadFile}
            />
        </div>
    );
};

const FundDetails: React.FC<FundDetailsProps> = ({ fund: formData, updateFund: setFormData }) => {
    const [appUser] = useAppUserContext();
    const isDisabled = !appUser.roles.includes(ERole.DocumentManager);

    return (
        <Card className={`${baseClass}__overview`}>
            <CardContent>
                {formData?.fundType === FundType.UMBRELLA && <UmbrellaDetails fund={formData} updateFund={setFormData} isDisabled={isDisabled} />}
                {formData?.fundType === FundType.SINGLE_FUND && <SingleFundDetails fund={formData} updateFund={setFormData} isDisabled={isDisabled} />}
                {formData?.fundType === FundType.SUBFUND && <SubFundDetails fund={formData} updateFund={setFormData} isDisabled={isDisabled} />}
            </CardContent>
        </Card>
    );
};
export default FundDetails;
