import React, { useEffect, useState } from "react";
import { publish } from "pubsub-js";
import { NetworkStatus, useLazyQuery } from "@apollo/client";
import classNames from "classnames";

import { getFilenameFromContentDisposition } from "@src/common/util";
import { useAppUserContext, useNotificationContext } from "@src/common/Context";
import { API_ENDPOINT } from "@src/common/config";
import { ERole, initialTemplate, PSChannel, Template } from "@src/common/types";
import axios from "@src/common/http";
import Page, { Title } from "@components/containers/Page";
import { Button, Card, CardHeader, CardContent, CardFooter, BackToTop, Upload as FileUpload, Icon, Radio } from "@components/common";

import DataLoads from "../_components/DataLoads";
import "./RegulatoryData.scss";
import { GET_REGULATORY_LOADS } from "@src/common/graphql";
import { errors } from "@src/common/errors";
import { differenceInHours, formatDistanceToNow } from "date-fns";

const baseClass = "acl-page-regulatory-data";

type RegularDataTemplate = {
    eet: Template;
    ept: Template;
    emt: Template;
};

const initialRegularDataTemplate = {
    eet: initialTemplate,
    ept: initialTemplate,
    emt: initialTemplate,
};

const RegulatoryData: React.FC<{}> = () => {
    const [appUser, _setAppUser] = useAppUserContext();
    const [uploadType, setUploadType] = useState<string>("emt");
    const [downloadType, setDownloadType] = useState<string>("emt");
    const [loading, setLoading] = useState<boolean>(true);
    const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
    const [downloading, setDownloading] = useState<boolean>(false);
    const [template, setTemplate] = useState<RegularDataTemplate>(initialRegularDataTemplate);
    const { sendNotification } = useNotificationContext();

    const [getRegulatoryLoads, { loading: loadingLoads, data, refetch: refetchLoads, networkStatus }] = useLazyQuery(GET_REGULATORY_LOADS, {
        variables: { companyId: appUser.companyId },
        notifyOnNetworkStatusChange: true,
    });

    const getFundData = async (isRefresh = false): Promise<void> => {
        isRefresh && setIsRefreshing(true);
        const response = await axios.get(`/fund-data-generation`);
        const { status, data } = response;

        if (status === 200) {
            setTemplate({
                eet: data.eet,
                ept: data.ept,
                emt: data.emt,
            });
        }
        setTimeout(() => {
            setIsRefreshing(false);
        }, 2000);
    };

    const generateFundData = async () => {
        const response = await axios.post(`/fund-data-generation?type=${downloadType}`);
        const { status } = response;

        if (status === 200) {
            sendNotification({
                timeout: 10000,
                type: "success",
                message: "Generation in progress.",
            });
            getFundData();
        }
    };

    useEffect(() => {
        if (!appUser.companyId) {
            publish(PSChannel.Error, errors.MISSING_COMPANY_ID);
            return;
        }

        getRegulatoryLoads().catch(error => console.error(error));
        getFundData();
    }, []);

    const handleDownload = (): void => {
        if (!appUser.companyId) {
            publish(PSChannel.Error, errors.MISSING_COMPANY_ID);
            return;
        }
        setDownloading(true);

        axios
            .get(`${API_ENDPOINT}/fund-data?type=${downloadType}`, { responseType: "blob" })
            .then(response => {
                if (response.status !== 200) {
                    setDownloading(false);
                    return;
                }
                // Get original file name from header
                const header = response.headers["content-disposition"];
                const filename = getFilenameFromContentDisposition(header, `${downloadType}-template.xlsx`);

                // Create blob link to download
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", filename);

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);

                setDownloading(false);
            })
            .catch(error => {
                setDownloading(false);
                console.error(error);
            });
    };

    useEffect(() => {
        setLoading(loadingLoads || networkStatus === NetworkStatus.refetch);
    }, [loadingLoads, networkStatus]);

    const handleUploadChange = React.useCallback(
        (e): void => {
            setUploadType(e.target.value);
        },
        [setUploadType],
    );

    const handleDownloadChange = React.useCallback(
        (e): void => {
            setDownloadType(e.target.value);
        },
        [setDownloadType],
    );

    const classes: string = classNames(baseClass, !appUser.roles.includes(ERole.DataManager) && `${baseClass}--no-upload`);

    return (
        <Page className={classes}>
            <Title className={`${baseClass}__title`}>
                Regulatory Data
                <a data-tooltip-id="tooltip" data-tooltip-content={"European MiFID Template (EMT) or European PRIIPs Template (EPT)​"} data-tooltip-place="top">
                    <Icon className={`${baseClass}__tooltip`} name="question-circle" />
                </a>
            </Title>
            <div className={`${baseClass}__static`}>
                {appUser.roles.includes(ERole.DataManager) && (
                    <Card className={`${baseClass}__static__upload`}>
                        <CardHeader className={`${baseClass}__static__header`}>
                            <h3 className={`${baseClass}__static__title`}>Upload</h3>
                        </CardHeader>
                        <CardContent className={`${baseClass}__static__content`}>
                            <FileUpload
                                url={`${API_ENDPOINT}/fund-data?type=regulatory-${uploadType}`}
                                extensions={[".xlsx"]}
                                multiple={false}
                                onSuccess={(): void => {
                                    if (!appUser.companyId) {
                                        publish(PSChannel.Error, errors.MISSING_COMPANY_ID);
                                        return;
                                    }
                                    refetchLoads();
                                }}
                            />
                            <div className={`${baseClass}__upload-options`}>
                                <Radio name="upload" value="emt" checked={uploadType === "emt"} label="EMT" onChange={handleUploadChange} />
                                <Radio name="upload" value="ept" checked={uploadType === "ept"} label="EPT" onChange={handleUploadChange} />
                                <Radio name="upload" value="eet" checked={uploadType === "eet"} label="EET" onChange={handleUploadChange} />
                            </div>
                        </CardContent>
                    </Card>
                )}
                <Card className={`${baseClass}__static__upload`}>
                    <CardHeader className={`${baseClass}__static__header`}>
                        <h3 className={`${baseClass}__static__title-top`}>Download</h3>
                        <h3 className={`${baseClass}__static__title`}>Regulatory Data Template</h3>
                    </CardHeader>
                    <CardContent className={`${baseClass}__static__content`}>
                        <p>
                            Generate and download the latest checked and validated regulatory data file to work on or simply upload the file you already have.
                        </p>
                        <div className={`${baseClass}__download-options`}>
                            <Radio name="download" value="emt" checked={downloadType === "emt"} label="EMT" onChange={handleDownloadChange} />
                            <Radio name="download" value="ept" checked={downloadType === "ept"} label="EPT" onChange={handleDownloadChange} />
                            <Radio name="download" value="eet" checked={downloadType === "eet"} label="EET" onChange={handleDownloadChange} />
                        </div>
                    </CardContent>
                    <CardFooter className={`${baseClass}__static__footer`}>
                        <div className={`${baseClass}__template-details`}>
                            {template[downloadType].generationInProgress ? (
                                <small>
                                    Your file is currently being generated.
                                    <br />
                                    We will notify you shortly to confirm it is ready for download.
                                    <br />
                                    Check your email inbox or click refresh to check generating status.
                                </small>
                            ) : template[downloadType].fileGeneratedTimestamp &&
                              differenceInHours(new Date(), new Date(template[downloadType].fileGeneratedTimestamp)) < 12 ? (
                                <>
                                    <div>
                                        <small>
                                            File name: <b>{template[downloadType].fileName}</b>
                                        </small>
                                    </div>
                                    <small>
                                        The generated file is available for download within 12 hours.
                                        <br />
                                        Generated {formatDistanceToNow(new Date(template[downloadType].fileGeneratedTimestamp))} ago.
                                    </small>
                                </>
                            ) : (
                                <small>
                                    There is currently no generated template.
                                    <br />
                                    Click Generate button to prepare template for download.
                                </small>
                            )}
                        </div>
                        <Button
                            themeColor="primary"
                            onClick={handleDownload}
                            disabled={
                                downloading ||
                                template[downloadType].generationInProgress ||
                                differenceInHours(new Date(), new Date(template[downloadType].fileGeneratedTimestamp)) >= 12
                            }
                            className={`${baseClass}__static__footer-button`}
                        >
                            {downloading ? (
                                <>
                                    <Icon name="loading" spacing="right" /> Downloading
                                </>
                            ) : (
                                "Download"
                            )}
                        </Button>
                        <Button
                            themeColor="secondary"
                            onClick={generateFundData}
                            disabled={template[downloadType].generationInProgress}
                            className={`${baseClass}__static__footer-button`}
                        >
                            {template[downloadType].generationInProgress ? (
                                <>
                                    <Icon name="loading" spacing="right" /> Generating
                                </>
                            ) : (
                                "Generate Template"
                            )}
                        </Button>
                        {template[downloadType].generationInProgress && (
                            <Button themeColor="primary" onClick={() => getFundData(true)} className={`${baseClass}__static__footer-button`}>
                                {isRefreshing ? (
                                    <>
                                        <Icon name="loading" spacing="right" /> Refreshing
                                    </>
                                ) : (
                                    "Refresh"
                                )}
                            </Button>
                        )}
                    </CardFooter>
                </Card>
            </div>
            <div className={`${baseClass}__status`}>
                <Card className={`${baseClass}__status__upload`}>
                    <CardHeader className={`${baseClass}__status__title`}>
                        <h3 className={`${baseClass}__card__title`}>Regulatory Data Loads</h3>
                        <Button
                            fill="outlined"
                            onClick={(): void => {
                                if (!appUser.companyId) {
                                    publish(PSChannel.Error, errors.MISSING_COMPANY_ID);
                                    return;
                                }
                                refetchLoads();
                            }}
                        >
                            Refresh
                        </Button>
                    </CardHeader>
                    <CardContent className={`${baseClass}__status__content`}>
                        <DataLoads dataset={data?.loads} loading={loading} />
                    </CardContent>
                </Card>
            </div>
            <BackToTop />
        </Page>
    );
};

export default RegulatoryData;
