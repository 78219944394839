import React from "react";

import styles from "./Textarea.module.scss";
import classNames from "classnames";

interface Props {
    value: string | number;
    readOnly?: boolean;
    id?: string;
    placeholder?: string;
    className?: string;
    rows?: number;
    required?: boolean;
    disabled?: boolean;
    onChange?: (event: any) => void;
}

const Textarea: React.FC<Props> = ({ value, rows = 1, placeholder, className, required = false, readOnly = false, disabled = false, ...props }) => {
    return (
        <div className={styles.textareaWrapper}>
            <textarea
                className={classNames(styles.textarea, className)}
                onChange={e => props.onChange(e.target.value)}
                value={value}
                placeholder={placeholder}
                required={required}
                readOnly={readOnly}
                disabled={disabled}
                rows={rows}
            />
        </div>
    );
};

export default Textarea;
