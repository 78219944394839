import React, { useState, useEffect } from "react";
import { publish } from "pubsub-js";
import { useLazyQuery, useQuery } from "@apollo/client";
import classNames from "classnames";
import Mark from "mark.js";

import { groupBy } from "@progress/kendo-data-query";
import { orderBy, SortDescriptor } from "@progress/kendo-data-query";

import { useAppUserContext } from "@src/common/Context";
import Page, { Title } from "@components/containers/Page";
import { Button, Card, CardHeader, CardContent, Chip, BackToTop, LoadingPanel, Flag } from "@components/common";
import { countryCodesByContinent } from "@src/common/countries";

import { CountryInfo, Dp, IDataset, ITransformedDp, ITransformedDps, Status, StatusClass, SubDistributionPartner } from "./types";
import ActivationForm from "./ActivationForm";
import DistributorDetails from "./DistributorDetails";
import "./DistributionNetwork.scss";
import { TreeList, TreeListCellProps, TreeListColumnProps, TreeListExpandChangeEvent, TreeListSortChangeEvent } from "@progress/kendo-react-treelist";
import CountryDetails from "./CountryDetails";
import { shouldShowUnfinishedFeatures } from "@src/common/config";
import { GET_COUNTRY_INFOS, GET_DISTRIBUTION_PARTNERS } from "@src/common/graphql";
import {
    Grid,
    GridColumn,
    GridCustomCellProps,
    GridDetailRowProps,
    GridExpandChangeEvent,
    GridRowProps,
    GridSortChangeEvent,
} from "@progress/kendo-react-grid";
import { CountryCodesByContinent, PSChannel } from "@src/common/types";
import { calculatePercentage, findContinentByCountryCode, getContinentsWithCountryNames } from "@src/common/util";
import { errors } from "@src/common/errors";
import { useWindowDimensions } from "@src/common/hooks";
import LabeledSelect from "@components/common/LabeledSelect";
import LabeledInput from "@components/common/LabeledInput";

const baseClass = "acl-page-distributors";

type DistributorDetails = {
    id: number;
};

export const transform = (dataset: IDataset[]): ITransformedDps => {
    // Group dataset by Country code
    const byCountry = groupBy(dataset, [{ field: "country" }]).map((country, index) => {
        const dpsCount = country.items?.length;
        const connectedDpsCount = country.items?.filter(element => element.isContracted).length;
        const dpCoverage = dpsCount > 0 ? calculatePercentage(connectedDpsCount, dpsCount) : null;
        let totalSubDps = 0;
        let totalConnectedSubDps = 0;
        country.items.forEach(element => {
            totalSubDps += element.subDistributionPartnersCount;
            if (element.isContracted) {
                totalConnectedSubDps += element.subDistributionPartnersCount;
            }
        });
        const subDpCoverage = totalSubDps > 0 ? calculatePercentage(totalConnectedSubDps, totalSubDps) : null;
        return {
            id: index + 1,
            country: country.value,
            dpsCount,
            connectedDpsCount,
            subDpsCount: totalSubDps,
            connectedSubDpsCount: totalConnectedSubDps,
            countryCode: country.items[0]?.countryCode,
            dpCoverage,
            subDpCoverage,
            items: country.items.map(item => ({ ...item, isGlobal: item.type === "DPG", continent: findContinentByCountryCode(item.countryCode) })),
            status: country.items.every(element => element.isContracted)
                ? StatusClass.Contracted
                : country.items.every(element => !element.isContracted)
                  ? StatusClass.Available
                  : StatusClass.PartlyContracted,
            statusOrder: country.items.every(element => !element.isContracted) ? StatusClass.Available : StatusClass.Active,
        };
    });
    return orderBy(byCountry, [
        { field: "statusOrder", dir: "asc" },
        { field: "country", dir: "asc" },
    ]);
};

export const getCountries = (dps: ITransformedDps, showInactive?: boolean): Array<string> => {
    const countries = [];
    showInactive ? dps.forEach(dp => dp.status === StatusClass.Available && countries.push(dp.country)) : dps.forEach(dp => countries.push(dp.country));
    return countries;
};

export const getStatus = (status: string): string => {
    switch (status) {
        case StatusClass.Contracted:
            return Status.Contracted;
        case StatusClass.PartlyContracted:
            return Status.PartlyContracted;
        default:
            return Status.Available;
    }
};

const CityCell: React.FC<TreeListCellProps> = ({ dataItem }) => <td className="city-filter">{dataItem.city}</td>;

const GlobalCell: React.FC<TreeListCellProps> = ({ dataItem }) => <td>{dataItem.hasOwnProperty("isContracted") && <>{dataItem.isGlobal ? "Yes" : "No"}</>}</td>;

const CoverageCell: React.FC<GridCustomCellProps> = ({ dataItem, field }) => (
    <td className={`${baseClass}__cell-coverage`}>
        {dataItem[field] === null ? (
            <div className={`${baseClass}__not-applicable`}>N/A</div>
        ) : (
            <Chip
                name={`${dataItem[field]}%`}
                type={dataItem[field] === 100 ? "success" : dataItem[field] < 50 ? "error" : "warning"}
                className={classNames(`${baseClass}__coverage`, `${baseClass}__chip`)}
            />
        )}
    </td>
);

const ConnectedDPCell: React.FC<GridCustomCellProps> = ({ dataItem }) => {
    const notConnected = dataItem.connectedDpsCount < dataItem.dpsCount && dataItem.dpsCount !== 0;
    return <td className={classNames(notConnected ? `${baseClass}__not-connected` : null, `${baseClass}__cell-connected`)}>{dataItem.connectedDpsCount}</td>;
};

const ConnectedSubDPCell: React.FC<GridCustomCellProps> = ({ dataItem }) => {
    const notConnected = dataItem.connectedSubDpsCount < dataItem.subDpsCount && dataItem.subDpsCount !== 0;
    return <td className={classNames(notConnected ? `${baseClass}__not-connected` : null, `${baseClass}__cell-connected`)}>{dataItem.connectedSubDpsCount}</td>;
};

const initialSort: Array<SortDescriptor> = [{ field: "name", dir: "asc" }];
const initialCountriesSort: Array<SortDescriptor> = [{ field: "status", dir: "asc" }];

const activeValues = [Status.Contracted, Status.Available];

const multinationalValues = ["Yes", "No"];

export const checkFilter = (element: Dp | SubDistributionPartner, key: "name" | "city", filterValue: string): boolean =>
    filterValue ? element[key]?.toLowerCase().includes(filterValue.toLowerCase()) : true;

const DistributionNetwork: React.FC<{}> = () => {
    const [appUser, _setAppUser] = useAppUserContext();
    const [dps, setDps] = useState<ITransformedDps>([]);
    const [filteredDps, setFilteredDps] = useState<ITransformedDps>([]);
    const [sort, setSort] = useState(initialSort);
    const [countriesSort, setCountriesSort] = useState(initialCountriesSort);
    const [nameFilter, setNameFilter] = useState<string>("");
    const [cityFilter, setCityFilter] = useState<string>("");
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [countriesList, setCountriesList] = React.useState([]);
    const [categoriesList, setCategoriesList] = React.useState([]);
    const [inactiveCountriesList, setInactiveCountriesList] = React.useState([]);
    const [selectedCountries, setSelectedCountries] = useState([]);
    const [selectedActive, setSelectedActive] = useState([]);
    const [selectedMultinational, setSelectedMultinational] = useState([]);
    const [showActivationForm, setShowActivationForm] = useState(false);
    const [countryToActivate, setCountryToActivate] = useState(null);
    const [allAvailable, setAllAvailable] = useState<boolean>(false);
    const [showDistributorModal, setDistributorShowModal] = useState<boolean>(false);
    const [showCountryModal, setCountryShowModal] = useState<boolean>(false);
    const [selectedDistributor, setSelectedDistributor] = useState<Dp>();
    const [selectedCountry, setSelectedCountry] = useState<string>();
    const [countryInfos, setCountryInfos] = useState<CountryInfo[]>([]);
    const [selectedCountryDps, setSelectedCountryDps] = useState<Dp[]>([]);
    const [countriesByContinents, setCountriesByContinents] = useState<CountryCodesByContinent>();
    const [selectedContinents, setSelectedContinents] = useState([]);
    const [continentsList, setContinentsList] = useState<string[]>([]);
    const { width } = useWindowDimensions();

    const openDistributorDetails = (dp): void => {
        setSelectedDistributor(dp);
        setDistributorShowModal(true);
    };

    const handleCountryOnClick = (isoa2code: string, dps: Dp[]): void => {
        if (countryInfos[isoa2code]) {
            setSelectedCountry(isoa2code);
            setSelectedCountryDps(dps);
            setCountryShowModal(true);
        }
    };

    const CountryCell: React.FC<GridCustomCellProps> = ({ dataItem }) => (
        <td>
            <div
                onClick={(event): void => {
                    if (countryInfos[dataItem.countryCode] && shouldShowUnfinishedFeatures) {
                        event.preventDefault();
                        handleCountryOnClick(dataItem.countryCode, dataItem.items);
                    }
                }}
                className={countryInfos[dataItem.countryCode] && shouldShowUnfinishedFeatures ? `${baseClass}__available_info` : null}
            >
                <Flag country={dataItem.countryCode} spacing="right" shape="circle" className={`${baseClass}__flag`} />
                {dataItem.country}
            </div>
        </td>
    );

    const ActiveCell: React.FC<TreeListCellProps> = ({ dataItem }) => (
        <td>
            {dataItem.hasOwnProperty("isContracted") && (
                <Chip
                    name={dataItem.isContracted ? Status.Contracted : Status.Available}
                    type={`${dataItem.isContracted ? "success" : "warning"}`}
                    className={`${baseClass}__chip`}
                />
            )}
        </td>
    );

    const NameCell: React.FC<TreeListCellProps> = ({ dataItem, level, expanded, onExpandChange }) => {
        const icons = [];
        const hasSubDps = dataItem.subDistributionPartnersCount > 0;
        icons.push(...level.slice(1).map((_x, i) => <span className="k-icon k-i-none" key={i} />));
        if (dataItem.isContracted && hasSubDps) {
            icons.push(
                <span
                    className={`${baseClass}__expand-icon k-icon k-font-icon k-i-${expanded ? "minus" : "plus"}`}
                    key="expand-collapse"
                    onClick={(event): void => onExpandChange(event, dataItem, level)}
                />,
            );
        } else if (!dataItem.isContracted && hasSubDps) {
            icons.push(
                <a
                    data-tooltip-id="tooltip"
                    data-tooltip-content={"Connect to this Partner to discover its sub-distributors network’s potential"}
                    data-tooltip-place="right"
                >
                    <span
                        className={`${baseClass}__expand-icon k-icon k-font-icon k-i-plus`}
                        title={"Connect to this Partner to discover its sub-distributors network’s potential"}
                    />
                </a>,
            );
        } else {
            icons.push(<span className="k-icon k-font-icon k-i-none" key={icons.length} />);
        }
        return (
            <td className={classNames("name-filter", `${baseClass}__name-filter`)}>
                <div className={`${baseClass}__nameCell-wrapper`}>
                    {icons}
                    {dataItem.isContracted ? (
                        <span
                            className={dataItem.isContracted ? `${baseClass}__name` : null}
                            onClick={(): void => {
                                dataItem.isContracted && openDistributorDetails(dataItem);
                            }}
                        >
                            {dataItem.name}
                        </span>
                    ) : !dataItem.subDistributionPartners ? (
                        <span>{dataItem.name}</span>
                    ) : (
                        <a data-tooltip-id="tooltip" data-tooltip-content={"Connect to this Partner to discover its potential"} data-tooltip-place="right">
                            <span className={`${baseClass}__name available`}>{dataItem.name}</span>
                        </a>
                    )}
                </div>
            </td>
        );
    };

    const SubDpsCell: React.FC<TreeListCellProps> = ({ dataItem }) => (
        <td className={!dataItem.isContracted && dataItem.subDistributionPartnersCount > 0 ? `${baseClass}__not-connected` : null}>
            {dataItem.subDistributionPartnersCount}
        </td>
    );

    const columns: TreeListColumnProps[] = [
        {
            field: "name",
            title: "Distribution Partner",
            cell: NameCell,
            expandable: true,
        },
        {
            field: "subDistributionPartnersCount",
            title: "Sub-Distributors",
            cell: SubDpsCell,
            width: 150,
        },
        {
            field: "city",
            title: "City",
            cell: CityCell,
            width: 190,
        },
        {
            field: "category",
            title: "Category",
            width: 260,
        },
        {
            field: "isContracted",
            title: "Status",
            cell: ActiveCell,
            width: 120,
        },
        {
            field: "isGlobal",
            title: "Multi-National",
            cell: GlobalCell,
            width: 120,
        },
    ];

    const requestActivation = (country): void => {
        country && setCountryToActivate(country);
        setShowActivationForm(true);
    };

    const ConnectCell: React.FC<GridCustomCellProps> = ({ dataItem }) => (
        <td className={`${baseClass}__connect-cell`}>
            {dataItem.status === StatusClass.Contracted ? (
                <Button disabled>Connected</Button>
            ) : (
                <Button
                    themeColor="primary"
                    onClick={(): void => requestActivation(dataItem.status === StatusClass.PartlyContracted ? null : dataItem.country)}
                >
                    {width > 1500 ? "Connect to a country or partner" : "Connect"}
                </Button>
            )}
        </td>
    );

    const ContractedCell: React.FC<GridCustomCellProps> = ({ dataItem }) => (
        <td className={`${baseClass}__contracted-cell`}>
            <Chip
                name={getStatus(dataItem.status)}
                type={`${dataItem.status === "inactive" ? "warning" : "success"}`}
                className={classNames(dataItem.status === "active-some" ? "partly_active" : null, `${baseClass}__chip`)}
            />
        </td>
    );

    const filterDocs = (): void => {
        const filtered: ITransformedDps = [];
        const showActive = selectedActive.length ? selectedActive.map(item => item.value).includes(Status.Contracted) : true;
        const showInactive = selectedActive.length ? selectedActive.map(item => item.value).includes(Status.Available) : true;
        const showLocal = selectedMultinational.length ? selectedMultinational.map(item => item.value).includes("No") : true;
        const showGlobal = selectedMultinational.length ? selectedMultinational.map(item => item.value).includes("Yes") : true;

        const continentsFilter = selectedContinents.length ? selectedContinents.map(item => item.value) : continentsList;
        const countriesFilter = selectedCountries.length ? selectedCountries.map(item => item.value) : countriesList;
        const categoriesFilter = selectedCategories.length ? selectedCategories.map(item => item.value) : categoriesList;

        dps.forEach(item => {
            if (countriesFilter.includes(item.country)) {
                const currentItem = {
                    ...item,
                    itemsExpanded:
                        !!nameFilter ||
                        !!cityFilter ||
                        selectedContinents.length > 0 ||
                        selectedCountries.length > 0 ||
                        selectedActive.length > 0 ||
                        selectedCategories.length > 0 ||
                        selectedMultinational.length > 0,
                };

                const countryDps = item.items.reduce((acc: Dp[], dp) => {
                    const subDps = dp.subDistributionPartners;
                    const filteredSubDps = subDps.filter(subDp => checkFilter(subDp, "name", nameFilter) && checkFilter(subDp, "city", cityFilter));

                    const byName = checkFilter(dp, "name", nameFilter);
                    const byCity = checkFilter(dp, "city", cityFilter);
                    const byCategory = categoriesFilter.length > 0 ? categoriesFilter.includes(dp.category) : true;
                    const byContinent = continentsFilter.length > 0 ? continentsFilter.includes(dp.continent) : true;
                    const byActive = (!dp.isContracted && showInactive) || (dp.isContracted && showActive);
                    const byGlobal = (!dp.isGlobal && showLocal) || (dp.isGlobal && showGlobal);

                    const subDpFilters = !!nameFilter || !!cityFilter;
                    const dpExclusiveFilters = byContinent && byCategory && byActive && byGlobal;
                    const dpIncluded = byName && byCity;
                    const subDpsIncluded = filteredSubDps.length > 0;

                    if (dpExclusiveFilters && (dpIncluded || subDpsIncluded)) {
                        acc.push({
                            ...dp,
                            subDistributionPartners: filteredSubDps,
                            treeListExpanded: subDpFilters,
                        });
                    }

                    return acc;
                }, []);
                countryDps.length > 0 && filtered.push({ ...currentItem, items: countryDps });
            }
        });

        setFilteredDps(filtered);
    };

    const onToggleActivationForm = (): void => {
        setShowActivationForm(!showActivationForm);
        setCountryToActivate(null);
    };

    function handleExpandChange(e: TreeListExpandChangeEvent): void {
        setFilteredDps(dps => {
            const result = dps.map(partner => {
                if (partner.country === e.dataItem.country) {
                    return {
                        ...partner,
                        items: partner.items?.map(item => {
                            if (item.id === e.dataItem.id) {
                                return {
                                    ...item,
                                    treeListExpanded: !item.treeListExpanded,
                                };
                            } else {
                                return item;
                            }
                        }),
                    };
                } else {
                    return partner;
                }
            });

            return result;
        });
    }

    const [getDistributionPartners, { loading: dpLoading }] = useLazyQuery(GET_DISTRIBUTION_PARTNERS, {
        variables: { companyId: appUser.companyId },
        fetchPolicy: "cache-first",
        onCompleted: data => {
            const complianceDetailsMap =
                data.allComplianceDetails?.reduce((acc: Record<string, { risk: number; lastDDDate: string }>, details) => {
                    const { omniId, risk, lastDDDate } = details;
                    if (omniId) {
                        acc[omniId.toString()] = { risk, lastDDDate };
                    }
                    return acc;
                }, {}) ?? {};

            data?.distributionPartners.every(e => !e.isContracted) && setAllAvailable(true);
            const dps =
                data?.distributionPartners
                    .filter(dp => dp.country)
                    .map((dp: Dp) => {
                        const { omniId, subDistributionPartners } = dp;
                        const sortedSubDistributionPartners = [...(subDistributionPartners ?? [])]?.sort((first, second) => {
                            const firstName: string = first?.name ?? "";
                            const secondName: string = second?.name ?? "";
                            return firstName.localeCompare(secondName);
                        });
                        return {
                            ...dp,
                            subDistributionPartners: sortedSubDistributionPartners ?? [],
                            risk: complianceDetailsMap[omniId]?.risk,
                            lastDDDate: complianceDetailsMap[omniId]?.lastDDDate,
                        };
                    }) ?? [];
            const categories = [...new Set(dps.map(obj => obj.category))].filter(obj => obj).sort();
            setCategoriesList(categories);
            const transformedDPs = transform(dps);
            setCountriesList(getCountries(transformedDPs).sort());
            setInactiveCountriesList(getCountries(transformedDPs, true).sort());
            setDps(transformedDPs);
        },
    });

    useEffect(() => {
        if (!appUser.companyId) {
            publish(PSChannel.Error, errors.MISSING_COMPANY_ID);
            return;
        }
        getDistributionPartners().catch(error => console.error(error));
    }, []);

    const { loading: countryLoading } = useQuery(GET_COUNTRY_INFOS, {
        fetchPolicy: "cache-first",
        onCompleted: data => {
            const countryInfos = data.countryInfos;
            setCountriesByContinents(getContinentsWithCountryNames(countryInfos, countryCodesByContinent));
            const processedCountryInfos = countryInfos.reduce((acc, countryInfo: CountryInfo) => {
                if (
                    countryInfo.acolinScopeOfServices ||
                    countryInfo.countryFacts ||
                    countryInfo.expectedFees ||
                    countryInfo.investmentOpportunities ||
                    countryInfo.investorsAndDistributors ||
                    countryInfo.registration
                ) {
                    acc[countryInfo.isoa2code] = countryInfo;
                }

                return acc;
            }, []);
            setCountryInfos(processedCountryInfos);
        },
    });

    const expandCountryChange = (event: GridExpandChangeEvent): void => {
        const newData = filteredDps.map((item: ITransformedDp) => {
            if (item.id === event.dataItem.id) {
                item.itemsExpanded = !event.dataItem.itemsExpanded;
            }
            return item;
        });
        setFilteredDps(newData);
    };

    const DetailComponent = (props: GridDetailRowProps): React.ReactNode => {
        const dataItem = props.dataItem;
        return (
            <TreeList
                data={orderBy(dataItem.items, sort)}
                sort={sort}
                onSortChange={(e: TreeListSortChangeEvent): void => {
                    setSort(e.sort);
                }}
                sortable={true}
                expandField={"treeListExpanded"}
                subItemsField={"subDistributionPartners"}
                onExpandChange={handleExpandChange}
                columns={columns}
            />
        );
    };

    const rowRender = (trElement: React.ReactElement<HTMLTableRowElement>, props: GridRowProps): React.ReactElement => {
        const inactive = props.dataItem.status === "inactive";
        const green = { backgroundColor: "whitesmoke" };
        const red = { backgroundColor: "white" };
        // @ts-expect-error ignore
        return React.cloneElement(trElement, { style: inactive ? red : green }, trElement.props.children);
    };

    useEffect(() => {
        const handleClickOutside = (event): void => {
            if (event.target.classList.contains("k-overlay")) {
                setDistributorShowModal(false);
                setCountryShowModal(false);
            }
        };

        if (showDistributorModal || showCountryModal) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return (): void => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showDistributorModal, showCountryModal]);

    useEffect(() => {
        if (dps) {
            filterDocs();
        }
        setTimeout(() => {
            // Highlight searched name
            const markName = new Mark(document.querySelectorAll(".name-filter"));
            markName.unmark({
                done: () => markName.mark(nameFilter, { className: `${baseClass}__highlight` }),
            });
            // Highlight searched city
            const markCity = new Mark(document.querySelectorAll(".city-filter"));
            markCity.unmark({
                done: () => markCity.mark(cityFilter, { className: `${baseClass}__highlight` }),
            });
        });
    }, [dps, selectedContinents, selectedCountries, selectedCategories, selectedActive, selectedMultinational, nameFilter, cityFilter]);

    useEffect(() => {
        if (dps.length > 0 && countriesByContinents) {
            const filtered = {};

            Object.keys(countriesByContinents).forEach(continent => {
                const filteredCountries = countriesByContinents[continent].filter(countryObj => dps.some(data => data.country === countryObj));

                if (filteredCountries.length > 0) {
                    filtered[continent] = filteredCountries;
                }
            });

            setContinentsList(Object.keys(filtered));
        }
    }, [dps]);

    return (
        <Page className={baseClass}>
            <header className={`${baseClass}__header`}>
                <Title className={`${baseClass}__title`}>Distribution Network</Title>
            </header>
            {showActivationForm && (
                <ActivationForm countriesList={inactiveCountriesList} selectedCountry={countryToActivate} onClose={onToggleActivationForm} />
            )}
            <div className={`${baseClass}__main`}>
                {dpLoading || countryLoading ? (
                    <Card className={`${baseClass}__list`}>
                        <div className={`${baseClass}__loading-wrapper`}>
                            <LoadingPanel />
                        </div>
                    </Card>
                ) : allAvailable ? (
                    <Card className={classNames(`${baseClass}__list`, `${baseClass}__list-placeholder`)}>
                        <CardContent>
                            <p className={`${baseClass}__empty`}>You have not signed up to Acolin’s Distribution Network Management service.</p>
                            <p>
                                To find out more, please visit our{" "}
                                <a href="https://acolin.com" target="_blank">
                                    website
                                </a>{" "}
                                or contact your Acolin representative {appUser.pointsOfContact.crm?.fullName} at{" "}
                                <a href={`mailto:${appUser.pointsOfContact.crm?.email || "crmteam@acolin.com"}`}>
                                    {appUser.pointsOfContact.crm?.email || "crmteam@acolin.com"}
                                </a>
                                .
                            </p>
                        </CardContent>
                    </Card>
                ) : (
                    <Card className={`${baseClass}__list`}>
                        <CardHeader className={`${baseClass}__main__header`}>
                            <h3 className={`${baseClass}__main__title`}>Distributors and their sub-distributors within Acolin's DNM</h3>
                        </CardHeader>
                        <CardContent>
                            <div className={`${baseClass}__filters`}>
                                <div className={`${baseClass}__filterGroup`}>
                                    {continentsList.length > 0 && (
                                        <LabeledSelect
                                            label="Continent"
                                            className={classNames(`${baseClass}__filter`, `${baseClass}__input-medium`)}
                                            options={continentsList.map(item => ({
                                                label: item,
                                                value: item,
                                            }))}
                                            placeholder="Select continents"
                                            value={selectedContinents}
                                            isMulti
                                            onChange={(e): void => setSelectedContinents(e)}
                                        />
                                    )}
                                    <LabeledSelect
                                        label="Country"
                                        className={classNames(`${baseClass}__filter`, `${baseClass}__input-medium`)}
                                        options={countriesList.map(item => ({
                                            label: item,
                                            value: item,
                                        }))}
                                        placeholder="Select countries"
                                        value={selectedCountries}
                                        isMulti
                                        onChange={(e): void => setSelectedCountries(e)}
                                    />
                                    <LabeledInput
                                        type="text"
                                        label="City"
                                        value={cityFilter}
                                        placeholder="Enter city"
                                        className={classNames(`${baseClass}__filter`, `${baseClass}__input-medium`)}
                                        onChange={(event): void => setCityFilter(event)}
                                    />
                                </div>
                                <div className={`${baseClass}__filterGroup`}>
                                    <LabeledInput
                                        type="text"
                                        label="Distributor/Sub-Distributor"
                                        placeholder="Enter name"
                                        value={nameFilter}
                                        className={classNames(`${baseClass}__filter`, `${baseClass}__input-medium`)}
                                        onChange={(event): void => setNameFilter(event)}
                                    />
                                    <LabeledSelect
                                        label="Relationship Status"
                                        className={classNames(`${baseClass}__filter`, `${baseClass}__input-medium`)}
                                        options={activeValues.map(item => ({
                                            label: item,
                                            value: item,
                                        }))}
                                        placeholder="Select"
                                        value={selectedActive}
                                        isMulti
                                        onChange={(e): void => setSelectedActive(e)}
                                    />
                                    <LabeledSelect
                                        label="Category"
                                        className={classNames(`${baseClass}__filter`, `${baseClass}__input-medium`)}
                                        options={categoriesList.map(item => ({
                                            label: item,
                                            value: item,
                                        }))}
                                        placeholder="Select categories"
                                        value={selectedCategories}
                                        isMulti
                                        onChange={(e): void => setSelectedCategories(e)}
                                    />
                                    <LabeledSelect
                                        label="Multi-National Distribution"
                                        className={classNames(`${baseClass}__filter`, `${baseClass}__input-medium`)}
                                        options={multinationalValues.map(item => ({
                                            label: item,
                                            value: item,
                                        }))}
                                        placeholder="Select"
                                        value={selectedMultinational}
                                        isMulti
                                        onChange={(e): void => setSelectedMultinational(e)}
                                    />
                                </div>
                            </div>
                            {filteredDps?.length ? (
                                <div className={`${baseClass}__tableWrapper`}>
                                    <Grid
                                        data={orderBy(filteredDps, countriesSort)}
                                        detail={DetailComponent}
                                        expandField="itemsExpanded"
                                        onExpandChange={expandCountryChange}
                                        scrollable="none"
                                        rowRender={rowRender}
                                        sort={countriesSort}
                                        onSortChange={(e: GridSortChangeEvent): void => {
                                            setCountriesSort(e.sort);
                                        }}
                                        sortable={true}
                                    >
                                        <GridColumn field="country" title="Country" cell={CountryCell} />
                                        <GridColumn
                                            field="status"
                                            title="Contract status"
                                            cell={ContractedCell}
                                            width={width > 1600 ? 200 : 150}
                                            headerClassName={`${baseClass}__contracted-header`}
                                        />
                                        <GridColumn title="Distribution Partners" headerClassName={`${baseClass}__parent-cell`}>
                                            <GridColumn
                                                field="connectedDpsCount"
                                                title="Connected"
                                                cell={ConnectedDPCell}
                                                width={width > 1600 ? 100 : 80}
                                                headerClassName={`${baseClass}__center`}
                                            />
                                            <GridColumn
                                                field="dpsCount"
                                                title="Possible"
                                                width={width > 1600 ? 100 : 80}
                                                className={`${baseClass}__cell-center`}
                                                headerClassName={`${baseClass}__center`}
                                            />
                                            <GridColumn
                                                field="dpCoverage"
                                                title="Coverage"
                                                width={width > 1600 ? 100 : 80}
                                                cell={CoverageCell}
                                                headerClassName={`${baseClass}__center`}
                                            />
                                        </GridColumn>
                                        <GridColumn title="Sub-Distributors" headerClassName={`${baseClass}__parent-cell`}>
                                            <GridColumn
                                                field="connectedSubDpsCount"
                                                title="Connected"
                                                cell={ConnectedSubDPCell}
                                                width={width > 1600 ? 100 : 80}
                                                headerClassName={`${baseClass}__center`}
                                            />
                                            <GridColumn
                                                field="subDpsCount"
                                                title="Possible"
                                                width={width > 1600 ? 100 : 80}
                                                className={`${baseClass}__cell-center`}
                                                headerClassName={`${baseClass}__center`}
                                            />
                                            <GridColumn
                                                field="subDpCoverage"
                                                title="Coverage"
                                                width={width > 1600 ? 100 : 80}
                                                cell={CoverageCell}
                                                headerClassName={`${baseClass}__center`}
                                            />
                                        </GridColumn>
                                        <GridColumn
                                            title={width > 1500 ? "Connect to an opportunity" : "Connect"}
                                            cell={ConnectCell}
                                            width={width > 1500 ? 260 : 120}
                                            headerClassName={`${baseClass}__center`}
                                        />
                                    </Grid>
                                </div>
                            ) : (
                                <div className={`${baseClass}__no-results`}>No results found for selected filter</div>
                            )}
                        </CardContent>
                    </Card>
                )}
            </div>
            {showDistributorModal && <DistributorDetails dp={selectedDistributor} onClose={(): void => setDistributorShowModal(false)} />}
            {showCountryModal && (
                <CountryDetails countryInfo={countryInfos[selectedCountry]} dps={selectedCountryDps} onClose={(): void => setCountryShowModal(false)} />
            )}
            <BackToTop />
        </Page>
    );
};

export default DistributionNetwork;
