import React, { useCallback, useEffect, useRef } from "react";
import classNames from "classnames";

import styles from "./RangeSlider.module.scss";

export type NumberRange = {
    min: number;
    max: number;
};

type RangeSliderProps = {
    range: NumberRange;
    value: NumberRange;
    onChange: (selectedRange: NumberRange) => void;
};

export const RangeSlider: React.FC<RangeSliderProps> = ({ range, value, onChange }) => {
    const rangeRef = useRef(null);

    const getPercent = useCallback((value: number) => Math.round(((value - range.min) / (range.max - range.min)) * 100), [range]);

    useEffect(() => {
        const minPercent = getPercent(value.min);
        const maxPercent = getPercent(value.max);
        if (rangeRef.current) {
            rangeRef.current.style.left = `${minPercent}%`;
            rangeRef.current.style.width = `${maxPercent - minPercent}%`;
        }
    }, [value]);

    return (
        <div className={styles.container}>
            <input
                type="range"
                min={range.min}
                max={range.max}
                value={value.min}
                onChange={e => {
                    const min = Math.min(Number(e.target.value), value.max - 1);
                    onChange({ min, max: value.max });
                }}
                className={classNames(styles.thumb, styles.thumb_left)}
                style={{ zIndex: value.min > range.max - 100 && "5" }}
            />
            <input
                type="range"
                min={range.min}
                max={range.max}
                value={value.max}
                onChange={e => {
                    const max = Math.max(Number(e.target.value), value.min + 1);
                    onChange({ min: value.min, max });
                }}
                className={classNames(styles.thumb, styles.thumb_right)}
            />

            <div className={styles.slider}>
                <div className={styles.slider__track} />
                <div ref={rangeRef} className={styles.slider__range} />
                <div className={styles.slider__left_value}>{value.min}</div>
                <div className={styles.slider__right_value}>{value.max}</div>
            </div>
        </div>
    );
};
