import React from "react";
import classNames from "classnames";

import "./FilterInput.scss";
import Input from "../Input";

const baseClass = "acl-filter-input";

type Props = {
    placeholder: string;
    className?: string;
    value: string | number;
    onChange?(filter: string | number | string[]): void;
};

const FilterInput: React.FC<Props> = ({ placeholder, onChange = (): void => {}, className, value }) => {
    const classes: string = classNames(baseClass, className);
    const handleChange = (event: any): void => onChange(event);

    return <Input placeholder={placeholder} className={classes} value={value} onChange={handleChange} />;
};

export default FilterInput;
