import React from "react";
import styles from "./Radio.module.scss";

export interface InputProps {
    label: string;
    checked: boolean;
    name: string;
    value?: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const Radio: React.FC<InputProps> = ({ label, checked, name, value, onChange }) => (
    <label className={styles.radioContainer}>
        {label}
        <input type="radio" checked={checked} name={name} value={value} onChange={onChange} />
        <span className={styles.radioCheckmark}></span>
    </label>
);

export default Radio;
